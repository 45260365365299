@import "react-grid-layout/css/styles.css";
@import "react-resizable/css/styles.css";
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

/* General */
body {
  margin: 0;
  padding: 1rem;
}

/* Grid */
.react-grid-item {
  max-width: 100%;
}

.react-grid-item:hover {
  z-index: 10;
}

#dee-popup-js .tooltip-inner {
  z-index: 1060;
  backdrop-filter: blur(3px);
}

/* Widgets */
.widget-component--imageComponent {
  position: absolute;
  inset: 0;
  text-align: center;
}

.widget-component--imageComponent img {
  max-width: 100%;
  max-height: 100%;
}

.widget-contentWrapper {
  position: absolute;
  inset: 0;
}

/* react table */
tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}

.Modal--zIndex-high {
  z-index: 1070 !important;
}

.Modal--zIndex-extra {
  z-index: 10006 !important;
}

.modal-backdrop--zIndex-high {
  z-index: 1060 !important;
}

.modal-backdrop--zIndex-extra {
  z-index: 10005 !important;
}

/* Fullscreen modal with grid - control app calculate (in JS) margin for more open fullscreen modals */
.Modal--fullwithGrid .Modal-content {
  margin-left: 10vw;
  margin-right: 0vw;
  display: block;
}

/* Tabs */
.Tabs {
  padding: 0;
  list-style-type: none;
  display: flex;
  gap: 8px;
}

.Tabs li {
  cursor: pointer;
  font-size: 1.5rem;
}

.Tabs li.disabled {
  cursor: auto;
  color: #ccc;
}

.Tabs .selected::after {
  width: 100%;
  height: 2px;
  margin-top: 4px;
  background-color: red;
  content: "";
  display: block;
  transition: all;
}

/* TODO: Delete with UI for account button (use in REPORTING) */
.like-segmented-button--first {
  margin-right: 1px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.like-segmented-button--middle {
  margin-right: 1px !important;
  border-radius: 0 !important;
}

.like-segmented-button--last {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/* tachyons */
.overflow-auto {
  overflow: auto;
}

.flex-grow-1 {
  flex-grow: 1;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
}

.border-end {
  border-right: 1px solid #eee;
}

.zIndex--10000 {
  z-index: 10000 !important;
}

.pointer-events-none {
  pointer-events: none;
}

.pos-sticky {
  position: sticky;
}

.SmartSelect--loading .SmartSelect__indicators .Loader--absolute {
  inset: -1px;
}

.recharts-wrapper {
  display: flex;
}

.react-grid-item.react-grid-placeholder {
  background: #f0d9ad;
}

.Sticky-Wrapper--isExpanded {
  margin-left: -24px;
  margin-right: -16px;
}

.Sticky-Wrapper--isExpanded .Sticky-Cell:first-child,
.Sticky-Wrapper--isExpanded .Sticky-Header .Sticky-Cell:first-child {
  padding-left: 24px;
}

.Sticky-Wrapper--isExpanded .Sticky-Cell:last-child,
.Sticky-Wrapper--isExpanded .Sticky-Header .Sticky-Cell:first-child {
  padding-right: 16px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  scrollbar-width: none;
}

.KpiRoadmap-rightShadow {
  box-shadow: 4px 0px 16px 0px rgba(0, 0, 0, 0.11);
  clip-path: inset(0 -20px 0 0);
}

.Heading.section-header,
.Heading.widget-header {
  line-height: 16px;
  padding-top: 0;
  padding-bottom: 0;
}

.section-description,
.widget-description {
  max-width: max(800px, 50%);
}

/* Show on hover */
.show-on-hover-wrapper .show-on-hover-element {
  visibility: hidden;
  opacity: 0;
}

.show-on-hover-wrapper:hover .show-on-hover-element {
  opacity: 1;
  visibility: visible;
}

.show-on-hover-wrapper .show-on-hover-wrapper .show-on-hover-element {
  visibility: hidden;
  opacity: 0;
}

.show-on-hover-wrapper .show-on-hover-wrapper:hover .show-on-hover-element {
  opacity: 1;
  visibility: visible;
}

.show-on-hover-wrapper:has(.ShowOnHoverIsActive) .show-on-hover-wrapper .show-on-hover-element {
  visibility: hidden;
  opacity: 0;
}

.show-on-hover-wrapper:has(.ShowOnHoverIsActive) .show-on-hover-wrapper .show-on-hover-element {
  visibility: hidden;
  opacity: 0;
}

.show-on-hover-wrapper:has(.ShowOnHoverIsActive) .show-on-hover-element {
  opacity: 1;
  visibility: visible;
}

.show-on-hover-wrapper .show-on-hover-wrapper:has(.ShowOnHoverIsActive) .show-on-hover-element {
  opacity: 1;
  visibility: visible;
}

.show-on-hover-element:has(*[data-active-element="true"]),
.show-on-hover-wrapper .show-on-hover-element:has(*[data-active-element="true"]),
.show-on-hover-wrapper .show-on-hover-wrapper .show-on-hover-element:has(*[data-active-element="true"]) {
  opacity: 1;
  visibility: visible;
}

.ReportWidget .Tile {
  border: 1px solid #E5E7E9
}

.ReportWidget:hover .Tile {
  border: 1px solid #CCD0D4
}

.ReportWidget .react-resizable-handle {
  opacity: 0;
}

.ReportWidget:hover .react-resizable-handle {
  opacity: 1;
}

/* Inline edit */

.Input.Input--inlineEdit input,
.Input.Input--inlineEdit textarea {
  background-color: transparent;
  border: none;
  margin: 0 -8px;
}

.Input.Input--inlineEdit textarea {
  padding-left: 8.5px;
  font-size: 12px;
  padding-top: 6px;
  height: 3.5em;
}

.Input--inlineEdit input:hover,
.Input--inlineEdit textarea:hover {
  padding-left: 7px;
  border: 1px solid #003a70b3;
  background-color: #FAFAFB;
}

.Input--inlineEdit textarea:hover {
  padding-top: 5px;
}

.Input--inlineEdit input:focus,
.Input--inlineEdit textarea:focus {
  padding-left: 6px;
  border: 2px solid #0085FF;
  background-color: #FAFAFB;
}

.Input--inlineEdit textarea:focus {
  padding-top: 4px;
}

.Input--inlineEdit--text input,
.Input--inlineEdit--text textarea {
  color: #596774;
  font-family: "Lato Regular";
  height: 18px;
  font-size: 12px;
  padding: 0 8px;
}

.Input--inlineEdit--text textarea {
  height: 36px;
}

.Input--inlineEdit--text input:hover,
.Input--inlineEdit--text textarea:hover {
  margin: 0px -8px;
  padding-left: 7.5px;
}

.Input--inlineEdit--text input:focus,
.Input--inlineEdit--text textarea:focus {
  margin: 0px -8px;
  padding-left: 6.5px;
}

.Input--inlineEdit--h1 input {
  height: 32px;
  font-size: 22px;
  font-family: "Lato Bold";
}

.Input--inlineEdit--h2 input {
  height: 24px;
  font-family: "Lato Bold";
  font-size: 16px;
}

.Input--inlineEdit--h3 input {
  height: 24px;
  font-family: "Lato Bold";
  font-size: 14px;
  min-width: fit-content;
}

.Input--inlineEdit--h4 input {
  height: 16px;
  font-family: "Lato Bold";
  font-size: 12px;
  padding-bottom: 7px;
}

.Input--inlineEdit--h4 input:focus {
  padding-bottom: 5.5px;
}

.Input--inlineEdit--h5 input {
  height: 16px;
  font-family: "Lato Bold";
  font-size: 12px;
}

.Sticky-Footer--TFoot {
  position: sticky !important;
  transition: top 0.2s ease;
  bottom: 0;
  z-index: 20;
  border-top: 2px solid #e5e7e9;
}

/* Button group */
.button-group {
  display: flex;
  flex-wrap: wrap;
}

.button-group .Button {
  margin: 0;
}

.button-group .Button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.button-group .Button + .Button,
.button-group .Button + .DropdownMenu .Button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Sticky-Table--delimiter .Sticky-Row.Sticky-Row--dashed .Sticky-Cell {
  border-bottom-style: dashed;
}

.Sticky-Table--delimiter .Sticky-Row--subRow:not(.Sticky-Row--lastSubRow) .Sticky-Cell {
  border-bottom-style: none;
}

.Sticky-Wrapper .Sticky-Row--subRow .Sticky-Cell:first-child {
  padding-left: 48px;
  position: relative;
}

.Sticky-Wrapper .Sticky-Row--subRow .Sticky-Cell:first-child:before,
.Sticky-Wrapper .Sticky-Row--subRow .Sticky-Cell:first-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 24px;
  height: 100%;
  width: 1px;
  background-color: #CCD0D4;
}

.Sticky-Wrapper .Sticky-Row--subRow .Sticky-Cell:first-child:after {
  height: 15px;
  width: 21px;
  top: 50%;
  transform: translateY(calc(-50% - 3px));
  background-image: url('./assets/arrow_rounded.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-color: unset;
}

.Sticky-Wrapper .Sticky-Row--subRow.Sticky-Row--lastSubRow .Sticky-Cell:first-child:before {
  height: calc(50% - 8px);
}

.Sticky-Row--highlighted .Sticky-Cell {
  background: #FAFAFB;
}

.Sticky-Table--withFocus .Sticky-Body .Sticky-Row:not(.Sticky-Row--focused) .Sticky-Cell > * {
  opacity: 0.5;
}

/* TODO: Move to app helper */
.pointer-events-none {
  pointer-events: none !important;
}

.align-vertical {
  top: 50%;
  transform: translateY(-50%);
}
